<template>
  <Notifications />
</template>

<script>
import Notifications from "@/components/Notifications/Index.vue";

export default {
  name: "PatientNotifications",
  components: { Notifications },
};
</script>
